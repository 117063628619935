
import { FormController } from '@icepanel/app-form'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { SupportModule } from '@/modules/support/store'
import { UserModule } from '@/modules/user/store'

import * as analytics from '../helpers/analytics'
import { OrganizationModule } from '../store'

@Component({
  components: {
    Dialog
  },
  name: 'OrganizationDeleteDialog'
})
export default class extends Vue {
  organizationModule = getModule(OrganizationModule, this.$store)
  supportModule = getModule(SupportModule, this.$store)
  userModule = getModule(UserModule, this.$store)

  @Ref() readonly dialog!: Dialog

  formController = new FormController({
    initialModel: {},
    validationRules: {}
  })

  get currentOrganizationId () {
    return this.$params.organizationId
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)!
  }

  created () {
    this.formController.submitHandler = async () => {
      const currentOrganizationId = this.currentOrganizationId

      await this.organizationModule.organizationDelete(currentOrganizationId)

      analytics.organizationDelete.track(this, {
        organizationId: [currentOrganizationId]
      })

      const nextOrganizationId = this.organizationModule.organizations.find(o => o.id !== currentOrganizationId)?.id
      if (nextOrganizationId) {
        await this.$router.push({
          name: 'landscapes',
          params: {
            organizationId: nextOrganizationId
          }
        })
      } else {
        await this.$router.push({
          name: 'organizations'
        })
      }

      this.organizationModule.removeOrganization(currentOrganizationId)
    }
  }

  opened () {
    analytics.organizationDeleteDialog.track(this, {
      organizationId: [this.currentOrganizationId]
    })
  }
}
