
import { FormController, IVForm } from '@icepanel/app-form'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { LandscapeModule } from '@/modules/landscape/store'
import { UserModule } from '@/modules/user/store'

import DeleteDialog from '../components/delete-dialog.vue'
import * as analytics from '../helpers/analytics'
import { OrganizationModule } from '../store'

@Component({
  components: {
    DeleteDialog
  },
  name: 'OrganizationOverview'
})
export default class extends Vue {
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  userModule = getModule(UserModule, this.$store)

  @Ref() readonly nameForm!: IVForm
  @Ref() readonly nameInput!: HTMLElement

  nameFormController = new FormController({
    initialModel: {
      name: ''
    },
    validationRules: {
      name: [v => !!v]
    }
  })

  get currentOrganizationId () {
    return this.$params.organizationId
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)!
  }

  get currentOrganizationPermission () {
    return this.organizationModule.organizationPermission(this.currentOrganization)
  }

  get landscapes () {
    return this.landscapeModule.landscapes
  }

  get landscapesListStatus () {
    return this.landscapeModule.landscapesListStatus
  }

  get contactLink () {
    const name = encodeURIComponent(this.userModule.user?.name || '')
    const email = encodeURIComponent(this.userModule.user?.email || '')
    return `https://icepanel.io/contact?email=${email}&name=${name}`
  }

  get nameFormIcon () {
    if (this.nameFormController.submitting) {
      return undefined
    } else if (this.nameFormController.disabled) {
      return '$fas-pencil-alt'
    } else {
      return '$fas-check'
    }
  }

  @Watch('currentOrganizationId')
  onCurrentOrganizationIdChanged (currentOrganizationId: string) {
    if (currentOrganizationId && this.landscapesListStatus.loadingInfo.organizationId !== currentOrganizationId && this.landscapesListStatus.successInfo.organizationId !== currentOrganizationId) {
      this.landscapeModule.setLandscapes([])
      this.landscapeModule.landscapesList(currentOrganizationId)
    }
  }

  created () {
    this.nameFormController.submitHandler = async model => {
      await this.organizationModule.updateOrganization({
        organizationId: this.currentOrganization.id,
        props: {
          name: model.name
        }
      })

      analytics.organizationUpdate.track(this, {
        organizationId: [this.currentOrganization.id],
        organizationName: model.name
      })
    }
  }

  mounted () {
    if (this.currentOrganizationId && this.landscapesListStatus.loadingInfo.organizationId !== this.currentOrganizationId && this.landscapesListStatus.successInfo.organizationId !== this.currentOrganizationId) {
      this.landscapeModule.setLandscapes([])
      this.landscapeModule.landscapesList(this.currentOrganizationId)
    }

    this.nameFormController.setInitialModel({
      name: this.currentOrganization.name
    })
    this.nameFormController.resetModel()

    analytics.organizationOverviewScreen.track(this, {
      organizationId: [this.currentOrganization.id]
    })

    this.nameFormController.disabled = true
    this.nameFormController.on('success', () => {
      this.nameFormController.disabled = true
    })
  }

  nameFormSubmit () {
    if (!this.nameFormController.submitting) {
      if (this.nameFormController.disabled) {
        this.nameFormController.disabled = false
        this.nameInput.focus()
      } else if (this.currentOrganization.name === this.nameFormController.model.name) {
        this.nameFormController.disabled = true
      } else {
        this.nameFormController.submit(this.nameForm)
      }
    }
  }
}
